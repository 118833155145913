/* You can add global styles to this file, and also import other style files */
@import url("https://cdn.materialdesignicons.com/2.1.19/css/materialdesignicons.min.css");

h2, h3 {
    font-weight: 500;
}

body {
    margin: 0;
    font-family: 'Roboto';
}

.content {
    margin: 0 30px;
}

.hidden {
    display: none;
}

mat-form-field.mat-form-field {
    display: block;
}

.table-container {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-width: 300px;
    overflow: auto;
    padding: 15px 30px;
}

.mat-fab.md-fab-bottom-right {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 9999;
}

.mat-mini-fab, .mat-fab {
    mat-icon:before {
        vertical-align: -webkit-baseline-middle;
    }
}

@mixin material-icon($font-size) {
    font-size: $font-size;
    height: $font-size;
    width: $font-size;
}

.material-icons {
    /* Rules for sizing the icon. */
    &.md-18 { @include material-icon(18px); }
    &.md-24 { @include material-icon(24px); }
    &.md-36 { @include material-icon(36px); }
    &.md-48 { @include material-icon(48px); }

    /* Rules for using icons as black on a light background. */
    &.md-dark { color: rgba(0, 0, 0, 0.54); }
    &.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

    /* Rules for using icons as white on a dark background. */
    &.md-light { color: rgba(255, 255, 255, 1); }
    &.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }
}

.mat-button {
    text-transform: uppercase;
}

mat-dialog-container {
    mat-dialog-content {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    mat-dialog-actions {
        flex-direction: row-reverse;
    }
}

ngx-wig {
    display: block;
    margin-bottom: 1.25em;
}

ngx-mat-file-input {
    word-break: break-all;
}